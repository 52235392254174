import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enGB from "date-fns/locale/en-GB";
import "react-big-calendar/lib/sass/styles.scss";

const locales = {
  "en-GB": enGB,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const handleSelectEvent = (event) => {
  window.location = event.url;
};

const App = ({ events }) => (
  <div
    style={{
      height: "70vh",
    }}
    className="hidden sm:block"
  >
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ minHeight: 600 }}
      onSelectEvent={handleSelectEvent}
      //   onNavigate={setSelectedDate}
      showAllEvents
      popup
      //   eventPropGetter={eventStyleGetter}
    />
  </div>
);

const Countdown = ({ events }) => {
  const [nextEvent] = useState(
    events?.find((ev) => +new Date(ev.start) >= +new Date()) ?? false
  );
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  function updateTime() {
    const eventTime = +new Date(nextEvent.start);
    const currentTime = +new Date();
    const diff = eventTime - currentTime;

    let seconds = Math.floor(diff / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    setTime({
      days,
      hours,
      minutes,
      seconds,
    });
  }

  useState(() => {
    if (!nextEvent) return;
    setInterval(updateTime, 1000);
  }, []);

  const Num = ({ children, name }) => (
    <div className="font-bold mx-5 pb-3 flex flex-col items-center">
      {children}
      <span className="text-xs text-gray-500">{name}</span>
    </div>
  );

  return (
    nextEvent && (
      <>
        <Num name="days">{time.days}</Num>
        <Num name="hours">{time.hours}</Num>
        <Num name="minutes">{time.minutes}</Num>
        <Num name="seconds">{time.seconds}</Num>
      </>
    )
  );
};

export default function EventsCalendar(el) {
  const events = JSON.parse(el.dataset.events);
  const calEl = el.querySelector("[data-calendar]");
  const countdownEl = el.querySelector("[data-countdown]");

  console.log(events);

  ReactDOM.render(<App events={events} />, calEl);
  ReactDOM.render(<Countdown events={events} />, countdownEl);
}
