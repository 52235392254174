import "core-js/stable";
import "regenerator-runtime/runtime";
import barba from "@barba/core";
import "./app.scss";
import Slider from "./js/Slider";
import lazySizes from "lazysizes";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import Headroom from "headroom.js";
import Animations from "./js/Animations";
import Popup from "./js/Popup";
import Form from "./js/Form";
import EventsCalendar from "./js/EventsCalendar";
import Menu from "./js/Menu";
import MediaFilter from "./js/MediaFilter";

let actions = {};
function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}

barba.hooks.beforeEnter((data) => {});
barba.hooks.after((data) => {
  runActions();
});

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

gsap.registerPlugin(ScrollTrigger);
registerAction(Animations);

runActions();

function runActions() {
  console.log("runActions");
  action("MediaFilter", MediaFilter);
  action("Slider", Slider);
  action("Menu", Menu);
  action("Form", Form);
  action("EventsCalendar", EventsCalendar);
  Popup(document.querySelectorAll(".popup-btn"));
  Animations();
  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 200,
  });
  headroom.init();

  var wrapper = document.querySelector(".wrapper svg");
  var btnDraw = document.querySelector(".btn-draw");
  var btnErase = document.querySelector(".btn-erase");

  // We are only adding and removing the 'active' class,
  // the entire animation is defined in the CSS code
  function draw() {
    wrapper.classList.add("active");
  }

  function erase() {
    wrapper.classList.remove("active");
  }

  // // Add handlers to our buttons
  // btnDraw.addEventListener("click", draw, false);
  // btnErase.addEventListener("click", erase, false);

  // Play draw animation once
  setTimeout(draw, 300);
}
