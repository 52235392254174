import Flickity from "flickity";

function Slider(el) {
  el.classList.remove("hidden");
  const options = el.dataset?.options ? JSON.parse(el.dataset.options) : {};
  const indexEl = el.parentElement.querySelector("[data-index]");

  const flk = new Flickity(el, options);

  function nextSlide() {
    flk.next();
  }

  flk.on("change", (index) => {
    if (indexEl) indexEl.innerText = index + 1;
  });

  let averages = [];

  // flk.on("settle", () => {
  //   averages = [];
  // });

  function prevSlide() {
    flk.previous();
  }

  function setActiveBtn(btns, index) {
    btns.forEach((btn) => btn.classList.remove("active"));
    btns[index]?.classList.add("active");
  }

  if (options.customNav) {
    const btns = [...document.querySelectorAll(options.customNav + " .button")];

    btns.forEach((btn) => {
      btn.addEventListener("click", (e) => {
        const index = btns.indexOf(btn);
        flk.select(index);
      });
    });

    setActiveBtn(btns, 0);

    flk.on("change", (index) => {
      setActiveBtn(btns, index);
    });
  }

  const nextButton = document.querySelector(options?.nextButton);
  if (nextButton) addEvent("click", nextButton, nextSlide);

  const prevButton = document.querySelector(options?.prevButton);
  if (prevButton) addEvent("click", prevButton, prevSlide);

  return () => {
    console.log("destroy slider ");
    flk.destroy();
  };
}

export default Slider;
