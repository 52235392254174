import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import eventBus from "./utils/eventBus";
gsap.registerPlugin(ScrollTrigger);

export default function Animations() {
  gsap.registerPlugin(ScrollTrigger);

  const panels = [...document.querySelectorAll(".panel")];

  panels.forEach((panel, i) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: panel,
          start: `top ${20 + i * 1.5}%`,
          // end: '100%',
          endTrigger: panel.parentElement,
          pin: true,
          pinSpacing: false,
          scrub: 1,
          markers: false,
        },
      })
      .set(panel, {
        willChange: "transform",
      })
      // .to(panel, {
      //   scale: 0.97
      // })
      .to(panel, {
        duration: 1 / panels.length,
        // delay: 1 / panels.length * panels.length,
        y: "-=6%",
        scale: 0.9,
      })
      .to(panel, {
        duration: 1 / panels.length,
        delay: ((1 / panels.length) * panels.length - 1) * 1.1,
        // delay: panels.length - 1,
        opacity: 0,
      });
  });
}

// // Intro Heading fade in
// gsap.from(".center-title", {
//   stagger: 1,
//   duration: 1,

//   y: "100%",
//   scrollTrigger: {
//     trigger: ".center-title",
//     start: "top 75%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".center-svg-3", {
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".center-svg-3",
//     start: "top 60%",
//     end: "top 40%",
//     scrub: 2,
//   },
// });

// gsap.from(".center-title-2", {
//   duration: 1,

//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".center-title-2",
//     start: "top 90%",
//     end: "top 75%",
//     scrub: 2,
//   },
// });

// gsap.from(".center-sub", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".center-sub",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".center-des", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".center-des",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".center-btn1", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".center-btn1",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });
// gsap.from(".center-btn2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".center-btn2",
//     start: "top 50%",
//     end: "top 30%",
//     scrub: 2,
//   },
// });

// // Calender Title
// gsap.from(".calender-title", {
//   stagger: 1,
//   duration: 1,

//   y: "100%",
//   scrollTrigger: {
//     trigger: ".calender-title",
//     start: "top 100%",
//     end: "top 90%",
//     scrub: 2,
//   },
// });
// gsap.from(".calender-sub", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".calender-sub",
//     start: "top 90%",
//     end: "top 70%",
//     scrub: 2,
//   },
// });

// gsap.from(".calender-abs", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".calender-abs",
//     start: "top 90%",
//     end: "top 70%",
//     scrub: 2,
//   },
// });

// gsap.from(".calender-svg", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".calender-svg",
//     start: "top 90%",
//     end: "top 70%",
//     scrub: 2,
//   },
// });

// gsap.from(".calender-timer", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".calender-timer",
//     start: "top 70%",
//     end: "top 50%",
//     scrub: 2,
//   },
// });

// // Card Title

// gsap.from(".card-title-1", {
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-title-1",
//     start: "top 80%",
//     end: "top 70%",
//     scrub: 2,
//   },
// });
// gsap.from(".card-title-2", {
//   duration: 1,
//   opacity: 0,
//   y: "100%",
//   scrollTrigger: {
//     trigger: ".card-title-1",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });
// gsap.from(".card-sub", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-sub",
//     start: "top 100%",
//     end: "top 90%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-abs", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-abs",
//     start: "top 50%",
//     end: "top 40%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-svg-1", {
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-title-1",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });
// gsap.from(".card-svg-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-title-1",
//     start: "top 60%",
//     end: "top 50%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-svg-3", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-title-1",
//     start: "top 40%",
//     end: "top 30%",
//     scrub: 2,
//   },
// });

// // Card Slider

// gsap.from(".card-slider-head-1", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-slider-head-1",
//     start: "top 80%",
//     end: "top 70%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-slider-head-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-slider-head-2",
//     start: "top 80%",
//     end: "top 70%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-slider-head-3", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-slider-head-3",
//     start: "top 80%",
//     end: "top 70%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-slider-shead-1", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-slider-shead-1",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-slider-shead-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-slider-shead-2",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-slider-shead-3", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-slider-shead-3",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-slider-svg-1", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-slider-svg-1",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-slider-svg-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-slider-svg-2",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-slider-svg-3", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-slider-svg-3",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-slider-des-1", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-slider-des-1",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-slider-des-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-slider-des-2",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".card-slider-des-3", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".card-slider-des-3",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// // Banner Statement

// gsap.from(".statement-svg", {
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".statement-svg",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".statement-des", {
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".statement-des",
//     start: "top 70%",
//     end: "top 50%",
//     scrub: 2,
//   },
// });

// // Category

// gsap.from(".cat-title-1", {
//   stagger: 1,
//   duration: 1,

//   y: "100%",
//   scrollTrigger: {
//     trigger: ".cat-title-1",
//     start: "top 75%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-title-2", {
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-title-2",
//     start: "top 75%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-abs", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-abs",
//     start: "top 50%",
//     end: "top 40%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-svg-1", {
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-svg-1",
//     start: "top 70%",
//     end: "top 55%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-svg-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-svg-2",
//     start: "top 60%",
//     end: "top 50%",
//     scrub: 2,
//   },
// });

// // Key Contact

// gsap.from(".key-title-1", {
//   stagger: 1,
//   duration: 1,
//   y: "100%",
//   scrollTrigger: {
//     trigger: ".key-title-1",
//     start: "top 75%",
//     end: "top 60%",
//     scrub: 2,
//     onEnter() {},
//     onLeave() {},
//     onToggle() {
//       console.log(this);
//     },
//   },
// });

// gsap.from(".key-title-2", {
//   stagger: 1,
//   duration: 1,
//   y: "100%",
//   scrollTrigger: {
//     trigger: ".key-title-2",
//     start: "top 75%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// // News Block

// gsap.from(".news-button", {
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".news-button",
//     start: "top 85%",
//     end: "top 70%",
//     scrub: 2,
//     onToggle() {
//       console.log(this);
//     },
//   },
// });

// gsap.from(".news-title-1", {
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".news-title-1",
//     start: "top 75%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".news-title-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".news-title-2",
//     start: "top 60%",
//     end: "top 40%",
//     scrub: 2,
//   },
// });

// gsap.from(".news-abs", {
//   stagger: 1,
//   duration: 2,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".news-abs",
//     start: "top 65%",
//     end: "top 50%",
//     scrub: 2,
//   },
// });

// gsap.from(".news-svg-1", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".news-title-1",
//     start: "top 70%",
//     end: "top 65%",
//     scrub: 2,
//   },
// });

// gsap.from(".news-svg-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".news-title-1",
//     start: "top 50%",
//     end: "top 40%",
//     scrub: 2,
//   },
// });

// // News entry

// gsap.from(".news-entry", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".news-entry",
//     start: "top 70%",
//     end: "top 40%",
//     scrub: 2,
//   },
// });

// // Testimonials

// // Entry 1

// gsap.from(".stars-1-1", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 90%",
//     end: "top 80%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-1-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 80%",
//     end: "top 70%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-1-3", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-1-4", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 60%",
//     end: "top 50%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-1-5", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 50%",
//     end: "top 40%",
//     scrub: 2,
//   },
// });

// // Entry 2

// gsap.from(".stars-2-1", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 90%",
//     end: "top 80%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-2-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 80%",
//     end: "top 70%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-2-3", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-2-4", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 60%",
//     end: "top 50%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-2-5", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 50%",
//     end: "top 40%",
//     scrub: 2,
//   },
// });

// // Entry 3

// gsap.from(".stars-3-1", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 90%",
//     end: "top 80%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-3-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 80%",
//     end: "top 70%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-3-3", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-3-4", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 60%",
//     end: "top 50%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-3-5", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 50%",
//     end: "top 40%",
//     scrub: 2,
//   },
// });

// // Entry 4

// gsap.from(".stars-4-1", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 90%",
//     end: "top 80%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-4-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 80%",
//     end: "top 70%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-4-3", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 70%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-4-4", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 60%",
//     end: "top 50%",
//     scrub: 2,
//   },
// });

// gsap.from(".stars-4-5", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".stars",
//     start: "top 50%",
//     end: "top 40%",
//     scrub: 2,
//   },
// });

// // Testimonial Text

// gsap.from(".testimonial-text-1", {
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".testimonial-text-1",
//     start: "top 60%",
//     end: "top 40%",
//     scrub: 2,
//   },
// });

// gsap.from(".testimonial-text-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".testimonial-text-2",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".testimonial-text-3", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".testimonial-text-3",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".testimonial-text-4", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".testimonial-text-4",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// // text image cat

// // Scroll and add class

// // onEnter(self) {
// //   console.log("enter", self);
// //   self.trigger.classList.add("animation-add");
// // },
// // onLeave(self) {
// //   console.log("leave", self);
// // },

// gsap.from(".cat-entry-heading-1", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-entry-heading-1",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-entry-heading-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-entry-heading-2",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-entry-heading-3", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-entry-heading-3",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-entry-heading-4", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-entry-heading-4",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-entry-heading-5", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-entry-heading-5",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-entry-heading-6", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-entry-heading-6",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-entry-subHeading-1", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-entry-subHeading-1",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-entry-subHeading-2", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-entry-subHeading-2",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-entry-subHeading-3", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-entry-subHeading-3",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-entry-subHeading-4", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-entry-subHeading-4",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-entry-subHeading-5", {
//   stagger: 1,
//   duration: 1,
//   opacity: 0,
//   scrollTrigger: {
//     trigger: ".cat-entry-subHeading-5",
//     start: "top 80%",
//     end: "top 60%",
//     scrub: 2,
//   },
// });

// gsap.from(".cat-entry-bigtitle", {
//   stagger: 1,
//   duration: 1,
//   y: "130%",
//   scrollTrigger: {
//     trigger: ".cat-entry-bigtitle",
//     start: "top 95%",
//     end: "top 75%",
//     scrub: 2,
//   },
// });
